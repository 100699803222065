import React, { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Pagination from "../Pagination/Pagination";
import "./TableGridLayout.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faArrowUp,
  faFileExport,
} from "@fortawesome/free-solid-svg-icons";
import TableDetails from "../Table/ManuscriptTable/TableDetails";
import TableLoader from "../Table/TableLoader";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentPage } from "../../Redux/Action/filter.action";
import JournalTableDetails from "../Table/JournalTableGrid/JournalTableDetails";
import NoDataFound from "../NotFound/NoDataFound";
import { toast } from "react-toastify";
import fileDownload from "js-file-download";
import AxiosService from "../../utils/AxiosService";
import { title, toTitleCase } from "../Filter/Filter";
import ReactGA from "react-ga4";
// import EditorReportButton from "../PDFFile/EditorReportButton";
import { SpinnerLoader } from "../Loader/Loader";
import JournalTableDetailsDoubleHeader from "../DoubleHeaderTable/DoubleHeaderJournalTableGrid/JournalTableDetailsDoubleHeader";
// import PDFPieChart from '../PDFFile/PDFPieChart'
import { API_BASE_URL } from "../../utils/Constant";

export const download = (data, fileName) => {
  const blob = new Blob([data], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.setAttribute("hidden", "");
  a.setAttribute("href", url);
  a.setAttribute("download", fileName + ".csv");
  document.body?.appendChild(a);
  a.click();
  document.body?.removeChild(a);
};

// Function to convert the JSON(Array of objects) to CSV.
export const arrayToCsv = (headers, data) => {
  const csvRows = [];
  // getting headers.
  const headerValues = headers?.map((header) => {
    if (header?.isSubHeader) {
      return header.subHeaderDisplay.map((item, index) => {
        if (index == 0) {
          return header.display;
        } else {
          return "";
        }
      });
    }
    return header.display;
  });
  csvRows.push(headerValues.join(",")); // Push into array as comma separated values

  if (headers[1].subHeaderNumber >= 0) {
    const subHeaderValue = headers.map((subHeader) => {
      if (subHeader?.isSubHeader) {
        return subHeader.subHeaderDisplay.map((item, index) => {
          return item.display;
        });
      } else {
        return "";
      }
    });
    csvRows.push(subHeaderValue.join(","));
  }

  // Getting rows.
  for (const row of data) {
    const rowValues = headers.map((header) => {
      let escaped;
      if (header?.isSubHeader) {
        escaped = header.subHeaderDisplay.map((item, index) => {
          return ("" + row[header.field][item.field])?.replace(/"/g, '\\"');
        });
      } else {
        escaped = ("" + row[header.field])?.replace(/"/g, '\\"'); // To replace the unwanted quotes.
      }
      return headers[1].subHeaderNumber >= 0 ? Array.isArray(escaped) ? escaped : `"${escaped}"` : `"${escaped}"`; // To escape the comma in a address like string.
    });
    csvRows.push(rowValues.join(",")); // Push into array as comma separated values.
  }
  return csvRows.join("\n"); // To enter the next rows in the new line '\n'
};

export const generateCSV = (header, data, filename, start) => {
  header.unshift({ display: "NO", tableDisplay: true, field: "no" });
  for (let i = 1; i <= data?.length; i++) {
    data.map((d, i) => {
      return (d.no = i + 1 + start);
    });
  }
  const csvData = "\ufeff" + arrayToCsv(header, data);
  download(csvData, filename);
  header.shift({ display: "NO", tableDisplay: true, field: "no" });
};

const TableGridLayout = (props) => {
  const {
    start,
    offset,
    data,
    gridTitle,
    gridType,
    reqBody = {},
    current,
    setCurrent,
    loading,
    classes,
    setSort,
    sort,
    isModal = false,
    graphType,
  } = props;
  const dispatch = useDispatch();
  const { tableLoader, currentPage } = useSelector((state) => state.allFilters);
  let isTableloader = loading ? loading : tableLoader;
  const isDoi = data?.config?.find((d) => d.display === "DOI");
  const [parentRowData, setParentRowData] = useState({});
  const [isDownloading, setIsDownloading] = useState(false); // For editor pdf download

  const axiosService = new AxiosService();

  // if(gridType === "averageCitationGrid"){
  //   title = 'insights_by_average_citations'
  // }

  const onPageChange = (page) => {
    ReactGA.event({
      category: toTitleCase(title),
      action: "Pagination clicked",
      label: `current_page= ${page}`,
    });
    if (current) {
      setCurrent(page);
    } else {
      dispatch(setCurrentPage(page));
    }
  };

  const handleSort = (order, field) => {
    ReactGA.event({
      category: toTitleCase(title),
      action: `Sort`,
      label: `field="${field}", order="${order}"`,
    });
    setSort({
      field: field,
      order: order,
    });
    !isModal && dispatch(setCurrentPage(1));
  };

  const handleExportAll = (name) => {
    let pageTitle = "";
    if (gridType === "averageCitationGrid") {
      pageTitle =
        "MI_" + "insights_by_average_citations"?.split(" ")?.join("_");
    } else {
      pageTitle = "MI_" + title?.split(" ")?.join("_");
    }

    if (name === "doi") {
      ReactGA.event({
        category: toTitleCase(title),
        action: `Export DOI`,
        label: `DOI= ${start + 1} to ${start + data?.data?.length}`,
      });
      let fileName = `${pageTitle}_DOI_${start + 1}_to_${
        start + data?.data?.length
      }`;
      const header = data.config.filter((header) => header.display === "DOI");
      generateCSV(header, data.data, fileName, start);
    } else {
      ReactGA.event({
        category: toTitleCase(title),
        action: `Export Records`,
        label: `Records= ${start + 1} to ${start + data?.data?.length}`,
      });

      let fileName = `${pageTitle}_${start + 1}_to_${
        start + data?.data?.length
      }`;
      const header = data.config.filter(
        (header) => header.tableDisplay === true
      );
      generateCSV(header, data.data, fileName, start);
    }
  };

  function exportAll() {
    ReactGA.event({
      category: toTitleCase(title),
      action: `Exporte All Records`,
      label: `All records= ${data?.total}`,
    });

    toast.success("File download started...");
    // let pageTitle = "MI_"+ title?.split(" ")?.join("_")
    let pageTitle = "";
    if (gridType === "averageCitationGrid") {
      pageTitle =
        "MI_" + "insights_by_average_citations"?.split(" ")?.join("_");
    } else {
      pageTitle = "MI_" + title?.split(" ")?.join("_");
    }
    axiosService
      .exportAllRecords(gridType, reqBody)
      .then((res) => {
        fileDownload(res.data, pageTitle + "_all_records.xlsx");
        toast.success("File downloaded successfully!");
      })
      .catch((error) => {
        if (error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Something went wrong!");
        }
      });
  }

  // function exportMetaData() {
  //   // ReactGA.event({
  //   //   category: toTitleCase(title),
  //   //   action: `Exporte All Records`,
  //   //   label: `All records= ${data?.total}`,
  //   // });

  //   toast.success("File download started...");
  //   axiosService
  //     .exportMetaData()
  //     .then((res) => {
  //       fileDownload(res.data, "Master_data.xlsx");
  //       toast.success("File downloaded successfully!");
  //     })
  //     .catch((error) => {
  //       if (error.response.data.message) {
  //         toast.error(error.response.data.message);
  //       } else {
  //         toast.error("Something went wrong!");
  //       }
  //     });
  // }

  const auth_details = JSON.parse(sessionStorage.getItem('rat_auth_details'))
  const metaDataExport = auth_details?.metaData?.metadataexport
  const token = auth_details?.access_token

  return (
    <div className="mt-3 pb-4">
      <Card className={`${classes ? classes : ""}`}>
        <Card.Body className={`'px-0 pb-0' ${classes ? classes : ""}`}>
          <Row className=" mb-2 pb-2">
            {/* <Col sm={12}>
                  <PDFPieChart reqBody={reqBody} />
                </Col> */}
            <Col sm={12} lg={6} className="sm-flex-column-contaniner">
              <div className="my-2">
                <span className="h5 me-2">{gridTitle} </span>
                <span className="text-muted">
                  {data?.data?.length === 0 ? 0 : start + 1}-
                  {data?.data?.length === 0
                    ? 0 + data?.data?.length
                    : start + data?.data?.length}{" "}
                  of {data.total}
                </span>
              </div>
            </Col>
            <Col
              sm={12}
              lg={6}
              className="sm-flex-column-contaniner d-flex justify-content-end align-items-start mt-2"
            >
              <div className="btn-group exportBlock me-3" role="group">
                <button
                  id="btnGroupDrop1"
                  type="button"
                  className="exportBtnStyle border d-flex w-100 align-items-center justify-content-between"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  disabled={data?.data?.length === 0}
                >
                  <div>
                    <FontAwesomeIcon icon={faFileExport} className="file" />{" "}
                    <span>
                      Export
                      {isDownloading && <SpinnerLoader classes={"ms-3"} />}
                    </span>
                  </div>
                  <FontAwesomeIcon
                    icon={faAngleDown}
                    className="text-secondary"
                  />
                </button>
                <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                  <li className="listItem" onClick={() => handleExportAll()}>
                    <span>
                      Records ({start + 1} - {start + data?.data?.length}){" "}
                    </span>
                  </li>
                  {isDoi && (
                    <li
                      className="listItem"
                      onClick={() => handleExportAll("doi")}
                    >
                      <span>
                        DOIs ({start + 1} - {start + data?.data?.length})
                      </span>
                    </li>
                  )}
                  {gridTitle !== "Access Type" && (
                    <li className="listItem" onClick={() => exportAll()}>
                      <span>All Records (1 - {data?.total}) </span>
                    </li>
                  )}
                    {title === "cumulative analytics" && metaDataExport && (
                    <li className="listItem" onClick={() => {}}>
                      <a href={`${API_BASE_URL}/exportMetaData/${token}`} className="text-dark text-decoration-none"   download>
                      <span>Export Metadata </span>
                      </a>
                    </li>
                  )}

                  {/* {toTitleCase(title) === "Cumulative Analytics" &&
                    gridType !== "averageCitationGrid" && (
                      <li className="listItem">
                        <EditorReportButton
                          reqBody={reqBody}
                          isDownloading={isDownloading}
                          setIsDownloading={setIsDownloading}
                        />
                      </li>
                    )} */}
                </ul>
              </div>
              {gridTitle !== "Access Type" && (
                <Pagination
                  className="pagination-bar mb-0 pe-0"
                  currentPage={current ? current : currentPage}
                  totalCount={data?.total}
                  pageSize={offset}
                  onPageChange={(page) => onPageChange(page)}
                />
              )}
            </Col>
          </Row>

          {data?.config && data?.data ? (
            isTableloader ? (
              <TableLoader
                headLength={data?.config?.length}
                dataLength={data?.data?.length}
              />
            ) : data ? (
              gridTitle === "Published Manuscripts" ? (
                <TableDetails
                  tableData={data?.data}
                  headers={data?.config}
                  start={start}
                  handleSort={handleSort}
                  sort={sort}
                />
              ) : data?.hasSubHeader ? (
                <JournalTableDetailsDoubleHeader
                  gridTitle={gridTitle}
                  tableData={data?.data}
                  headers={data?.config}
                  hasSubHeader={data?.hasSubHeader}
                  start={start}
                  handleSort={handleSort}
                  sort={sort}
                  parentData={parentRowData}
                  setParentRowData={setParentRowData}
                  graphType={graphType}
                />
              ) : (
                <JournalTableDetails
                  gridTitle={gridTitle}
                  tableData={data?.data}
                  headers={data?.config}
                  start={start}
                  handleSort={handleSort}
                  sort={sort}
                  parentData={parentRowData}
                  setParentRowData={setParentRowData}
                  graphType={graphType}
                />
              )
            ) : (
              <NoDataFound />
            )
          ) : null}
        </Card.Body>
      </Card>
      {isModal && (
        <button
          className="gotoTopStyle"
          onClick={() => {
            if (isModal) {
              const element = document.getElementById("top-section");
              element.scrollIntoView({
                behavior: "smooth",
              });
            }
          }}
        >
          {" "}
          <FontAwesomeIcon icon={faArrowUp} />
        </button>
      )}
    </div>
  );
};

export default TableGridLayout;
