import React, { useEffect, useState } from 'react'
import AxiosService from '../../utils/AxiosService'
import "./CumulativeAnalytics.scss"
import TableGridLayout from '../../components/TableGridLayout/TableGridLayout'
import Filter, { handleGAEvent } from '../../components/Filter/Filter'
import { useDispatch, useSelector } from 'react-redux'
import CardDetails from '../../components/CardDetails/CardDetails'
import { setManuscriptGridData, setTableLoader } from '../../Redux/Action/filter.action'


const CumulativeAnalytics = () => {
  const {manuscriptGridData, searchValue,
  dateRange, currentPage, 
  rejectingJournal,
  acceptingJournal,
  publishers,
  countries,
  accessType,
  isPageReload
  } = useSelector((state)=> state.allFilters)
  const axiosService= new AxiosService()
  const [loading, setLoading] = useState({isApplyFilter: false, isClearFilter: false, isGo:false})
  const dispatch = useDispatch()
  const [sort, setSort] = useState({
    field: "crossref_citations",
    order: "desc"
  })
  const[reqBody, setReqBody] = useState({})
  
  const [status, setStatus]= useState("resolved")
  const [start, setStart] = useState(0)
    const offset = 10

    const getAllRecords =async ()=> {
      let startDate=""
      let endDate=""
      if(dateRange.rejectionStartDate.length > 10 || dateRange.rejectionEndDate.length > 10) {
        startDate= dateRange.rejectionStartDate.slice(0,10)
        endDate= dateRange.rejectionEndDate.slice(0,10)
      }
      else {
        startDate= dateRange.rejectionStartDate
        endDate= dateRange.rejectionEndDate
      }
      
      dispatch(setTableLoader(true))
      const start = (currentPage - 1) * offset;
      setStart(start)
      const reqBody = {
        "vendor": "",
        "start_date": startDate,
        "end_date": endDate,
        "status": status,
        "searchTerm": searchValue,
        "filter": {
          "s_journal": rejectingJournal,
          "p_journal": acceptingJournal,
          "p_publisher": publishers,
          "country": countries
        },
        "sort": {
          "field": sort.field,
          "order": sort.order
        },
        "graphFilter": {
        }
        }
        if(accessType){
          reqBody["filter"].oa= true
        }
      axiosService.getSearch(start, offset, reqBody).then(response => {
          if(response.data) {
              dispatch(setTableLoader(false))
              setLoading({...loading, isApplyFilter:false, isClearFilter: false, isGo:false})
              dispatch(setManuscriptGridData(response.data))
          }
      }).catch(error => {
        dispatch(setTableLoader(false))
      })
      setReqBody(reqBody)
      handleGAEvent(loading, reqBody)
  }

  useEffect(()=> {
    if(dateRange.rejectionEndDate){
      getAllRecords()
    }
  }, [currentPage, sort, isPageReload])

  useEffect(()=> {
    if((loading.isApplyFilter || loading.isClearFilter || loading.isGo) && dateRange.rejectionStartDate){
      setSort({
        field: "crossref_citations",
        order: "desc"
    })
    }
  }, [loading.isApplyFilter, loading.isClearFilter, loading.isGo])

  useEffect(()=>{
    window.scrollTo({top: 0, behavior: 'smooth'})
  },[])

 
  return (
    <div className=''>
      <Filter
      loading={loading}
      setLoading={setLoading}
      getAllRecords={getAllRecords}
      />
      <CardDetails
      setSort={setSort}
      getAllRecords={getAllRecords}
      loading={loading}
      status={status}
      setStatus={setStatus}
      />
      <TableGridLayout data={manuscriptGridData} 
      start={start} setStart={setStart} 
      offset={offset} 
      gridTitle={"Published Manuscripts"}
      getAllRecords={getAllRecords}
      sort={sort}
      setSort={setSort}
      reqBody={reqBody}
      gridType="manuscriptGrid"
      />
     
    </div>
  )
}

export default CumulativeAnalytics
