import React, { useEffect, useState } from 'react'
import Filter, { handleGAEvent } from '../../components/Filter/Filter'
import { useDispatch, useSelector } from 'react-redux'
import AxiosService from '../../utils/AxiosService'
import { setDateRange, setTableLoader } from '../../Redux/Action/filter.action'
import { setJournalGridData } from '../../Redux/Action/journal.action'
import { toast } from 'react-toastify'
import TableGridLayout from '../../components/TableGridLayout/TableGridLayout'
import "./Publishers.scss"
import ToggleSunBurst from '../../components/graphs/D3/Sunburst/ToggleSunburst';


const Publishers = () => {
  const [loading, setLoading] = useState({isApplyFilter: false, isClearFilter: false, isGo:false})
  const {
    journalType, dateRange, searchValue, currentPage,
    acceptingJournal, rejectingJournal, publishers, accessType, isPageReload, countries
  } = useSelector((state)=> state.allFilters)
  
  const {journalGrid} = useSelector((state)=> state.journal)
  const dispatch = useDispatch()
  const axiosService = new AxiosService()
  const [activeBtn, setActiveBtn]= useState("Publication")
  const [graphLoader, setGraphLoader] = useState(false)
  const[reqBody, setReqBody] = useState({})

  const [start, setStart] = useState(0)
  const offset = 10

  const [chartData, setChartData] = useState({})
  const [sort, setSort] = useState({
    field: "totalPublication",
    order: "desc"
  })

  function onResetChart() {
    setGraphLoader(true);
    setTimeout(() => {
      setGraphLoader(false);
    }, 500);
  }

  const getAllRecords= async()=>{
    const reqBody = await getQuery()
    if(reqBody){
      dispatch(setTableLoader(true))
      if(activeBtn==="Publication"){
        getPublisherChart()
      }else if(activeBtn==="Citation"){
        getCitationChart()
      }
      const localStart = (currentPage - 1) * offset;
      setStart(localStart);
      axiosService.getPublisherGridDetails(localStart, offset, reqBody).then(response => {
        if(response.data) {
            dispatch(setTableLoader(false))
            dispatch(setJournalGridData(response.data))
            setLoading({...loading, isApplyFilter:false, isClearFilter: false, isGo:false})
        }
      }).catch(error => {
        dispatch(setTableLoader(false))
        dispatch(setJournalGridData({}))
      }) 
      setReqBody(reqBody)
      handleGAEvent(loading, reqBody)
    }
  
  }

  const getQuery = async () => {
  let startDate=""
  let endDate=""

    if(dateRange.rejectionStartDate.length > 10 || dateRange.rejectionEndDate.length > 10) {
      startDate= dateRange.rejectionStartDate.slice(0,10)
      endDate= dateRange.rejectionEndDate.slice(0,10)
    }else {
      startDate= dateRange.rejectionStartDate
      endDate= dateRange.rejectionEndDate
    }
    const start = (currentPage - 1) * offset;
    setStart(start)

  const reqBody = {
    "vendor": "",
    "start_date": startDate,
    "end_date": endDate,
    "status": "resolved",
    "searchTerm": searchValue,
    "filter": {
      "s_journal": rejectingJournal,
      "p_journal": acceptingJournal,
      "p_publisher": publishers,
      "country": countries
    },
    "sort": {
      "field": sort.field,
      "order": sort.order
    }
  }

  if(accessType){
    reqBody["filter"].oa= true
  }

  return reqBody
}

  const getCitationChart = async() =>{
    const reqBody = await getQuery()
    setGraphLoader(true)
    const localStart = (currentPage - 1) * offset;
    setStart(localStart)
    if(reqBody){
      axiosService.getCitationChart(localStart,offset, reqBody).then(res=> {
        setChartData(res?.data)
        setGraphLoader(false)
      }).catch(error=> {
        toast.error(error?.message)
        setGraphLoader(false)
        setChartData({})
      })
    } 
  }

  const getPublisherChart = async() => {
    const reqBody = await getQuery()
    setGraphLoader(true)
    const localStart = (currentPage - 1) * offset;
    setStart(localStart)
    if(reqBody){
      axiosService.getPublisherChart(localStart, offset, reqBody).then(res=> {
        setChartData(res?.data)
        setGraphLoader(false)
      }).catch(error=> {
        toast.error(error?.message)
        setGraphLoader(false)
        setChartData({})
      })
    }
  }

  useEffect(()=> {
    if(dateRange.rejectionStartDate) {
      getAllRecords()
    }
  }, [currentPage, sort, isPageReload])

  useEffect(()=> {
    if((loading.isApplyFilter || loading.isGo) && dateRange.rejectionStartDate){
      getAllRecords()
    }
  },[loading.isApplyFilter, loading.isGo])

  useEffect(()=>{
    if(activeBtn==="Citation")
    {
      setActiveBtn("Publication")
      getAllRecords()
    }else {
      getAllRecords()
    }

  }, [loading.isClearFilter])

  useEffect(()=>{
    window.scrollTo({top: 0, behavior: 'smooth'})
  },[])

  return (
    <div>
      <Filter
      loading={loading}
      setLoading={setLoading}
      getAllRecords={getAllRecords}
      />
      <ToggleSunBurst data={chartData} activeBtn={activeBtn}
       setActiveBtn={setActiveBtn}
       onResetChart={onResetChart}
       getCitationChart={getCitationChart}
       getPublisherChart={getPublisherChart}
       loader={graphLoader}
      />

      <TableGridLayout data={journalGrid} 
      start={start} setStart={setStart} 
      offset={offset} 
      gridTitle={"Publishers"}
      getAllRecords={getAllRecords}
      sort={sort}
      setSort={setSort}
      reqBody={reqBody}
      gridType="publisherTrendGrid"
      />
    </div>
  )
}

export default Publishers
