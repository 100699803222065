import { API_BASE_URL } from "./Constant";
import { dataDisplayRequest } from "./auth_service/AuthServiceHelper";
import Axios from "axios";

/* Service layer class for all API related calls */
export default class AxiosService {
  // constructor() { }

  getRejectionStageAnalysisDetails(reqBody) {
    const config = {
      method: "post",
      url: API_BASE_URL + "/rejectionStageAnalytics",
      data: reqBody,
    };
    return dataDisplayRequest(config).then((response) => {
      return response;
    });
  }

  getStackedHorizontalBarGraphDetails(reqBody) {
    const config = {
      method: "post",
      url: API_BASE_URL + "/stackedHorizontalBarGraph",
      data: reqBody,
    };
    return dataDisplayRequest(config).then((response) => {
      return response;
    });
  }

  getPieChartDetails(reqBody) {
    const config = {
      method: "post",
      url: API_BASE_URL + "/pieChart",
      data: reqBody,
    };
    return dataDisplayRequest(config).then((response) => {
      return response;
    });
  }

  getSearch(start, offset, reqBody) {
    const config = {
      method: "post",
      url: API_BASE_URL + `/manuscriptGrid?start=${start}&offset=${offset}`,
      data: reqBody,
    };
    return dataDisplayRequest(config).then((response) => {
      return response;
    });
  }

  getMetaInfo(vendor) {
    const config = {
      method: "post",
      url: API_BASE_URL + "/metaInfo",
      data: { vendor: vendor },
    };
    return dataDisplayRequest(config).then((response) => {
      return response;
    });
  }

  getFilterSuggestion(reqBody) {
    const config = {
      method: "post",
      url: API_BASE_URL + "/suggest",
      data: reqBody,
    };
    return dataDisplayRequest(config).then((response) => {
      return response;
    });
  }

  getCardDetails(reqBody) {
    const config = {
      method: "post",
      url: API_BASE_URL + "/card",
      data: reqBody,
    };
    return dataDisplayRequest(config).then((response) => {
      return response;
    });
  }

  // insights by journal

  getJournalChartDetails(start, offset, reqBody) {
    const config = {
      method: "post",
      url: API_BASE_URL + `/journalTrendChart?start=${start}&offset=${offset}`,
      data: reqBody,
    };
    return dataDisplayRequest(config).then((response) => {
      return response;
    });
  }

  getSubjectCategoryChart(start, offset, reqBody) {
    const config = {
      method: "post",
      url:
        API_BASE_URL + `/subjectCategoryChart?start=${start}&offset=${offset}`,
      data: reqBody,
    };
    return dataDisplayRequest(config).then((response) => {
      return response;
    });
  }

  getSubjectCategoryGridDetails(start, offset, reqBody) {
    const config = {
      method: "post",
      url:
        API_BASE_URL +
        `/rejectSubjectCategoryGrid?start=${start}&offset=${offset}`,
      data: reqBody,
    };
    return dataDisplayRequest(config).then((response) => {
      return response;
    });
  }

  getRejectedJournalGridDetails(start, offset, reqBody) {
    const config = {
      method: "post",
      url:
        API_BASE_URL +
        `/rejectJournalTrendGrid?start=${start}&offset=${offset}`,
      data: reqBody,
    };
    return dataDisplayRequest(config).then((response) => {
      return response;
    });
  }

  getAcceptJournalGridDetails(start, offset, reqBody) {
    const config = {
      method: "post",
      url:
        API_BASE_URL +
        `/acceptJournalTrendGrid?start=${start}&offset=${offset}`,
      data: reqBody,
    };
    return dataDisplayRequest(config).then((response) => {
      return response;
    });
  }

  // inside by access type

  getAccessTypeGridDetails(start, offset, reqBody) {
    const config = {
      method: "post",
      url: API_BASE_URL + `/accessTypeGrid?start=${start}&offset=${offset}`,
      data: reqBody,
    };
    return dataDisplayRequest(config).then((response) => {
      return response;
    });
  }

  getAccessTypeChartDetails(start, offset, reqBody) {
    const config = {
      method: "post",
      url: API_BASE_URL + `/accessTypeChart?start=${start}&offset=${offset}`,
      data: reqBody,
    };
    return dataDisplayRequest(config).then((response) => {
      return response;
    });
  }

  // inside be publishers api

  getPublisherChart(start, offset, reqBody) {
    const config = {
      method: "post",
      url:
        API_BASE_URL +
        `/publisherTrendChart_d3?start=${start}&offset=${offset}`,
      data: reqBody,
    };
    return dataDisplayRequest(config).then((response) => {
      return response;
    });
  }

  getCitationChart(start, offset, reqBody) {
    const config = {
      method: "post",
      url:
        API_BASE_URL +
        `/publisherTrendCitationChart_d3?start=${start}&offset=${offset}`,
      data: reqBody,
    };
    return dataDisplayRequest(config).then((response) => {
      return response;
    });
  }

  getPublisherGridDetails(start, offset, reqBody) {
    const config = {
      method: "post",
      url: API_BASE_URL + `/publisherTrendGrid?start=${start}&offset=${offset}`,
      data: reqBody,
    };
    return dataDisplayRequest(config).then((response) => {
      return response;
    });
  }

  getPublisherExpandableGridDetails(start, offset, reqBody) {
    const config = {
      method: "post",
      url:
        API_BASE_URL +
        `/publisherExpandableGrid?start=${start}&offset=${offset}`,
      data: reqBody,
    };
    return dataDisplayRequest(config).then((response) => {
      return response;
    });
  }

  getPublicationTimeChart(start, offset, reqBody) {
    const config = {
      method: "post",
      url:
        API_BASE_URL + `/publicationTimeChart?start=${start}&offset=${offset}`,
      data: reqBody,
    };
    return dataDisplayRequest(config).then((response) => {
      return response;
    });
  }

  getPublicationTimeGrid(start, offset, reqBody) {
    const config = {
      method: "post",
      url:
        API_BASE_URL + `/publicationTimeGrid?start=${start}&offset=${offset}`,
      data: reqBody,
    };
    return dataDisplayRequest(config).then((response) => {
      return response;
    });
  }

  getAverageCitationChart(start, offset, reqBody) {
    const config = {
      method: "post",
      url:
        API_BASE_URL + `/averageCitationChart?start=${start}&limit=${offset}`,
      data: reqBody,
    };
    return dataDisplayRequest(config).then((response) => {
      return response;
    });
  }

  getAverageCitationGrid(start, offset, reqBody) {
    const config = {
      method: "post",
      url: API_BASE_URL + `/averageCitationGrid?start=${start}&limit=${offset}`,
      data: reqBody,
    };
    return dataDisplayRequest(config).then((response) => {
      return response;
    });
  }

  getJournalCascadeChart(start, offset, reqBody) {
    const config = {
      method: "post",
      url:
        API_BASE_URL +
        `/journalCascadeChart_d3?start=${start}&offset=${offset}`,
      data: reqBody,
    };
    return dataDisplayRequest(config).then((response) => {
      return response;
    });
  }

  getJournalCascadeGrid(start, offset, reqBody) {
    const config = {
      method: "post",
      url: API_BASE_URL + `/journalCascadeGrid?start=${start}&offset=${offset}`,
      data: reqBody,
    };
    return dataDisplayRequest(config).then((response) => {
      return response;
    });
  }

  //   /rejectionTrendChart
  // /rejectionTrendGrid

  getRejectingTrendChart(start, offset, reqBody) {
    const config = {
      method: "post",
      url:
        API_BASE_URL + `/rejectionTrendChart?start=${start}&offset=${offset}`,
      data: reqBody,
    };
    return dataDisplayRequest(config).then((response) => {
      return response;
    });
  }

  getRejectingTrendGrid(start, offset, reqBody) {
    const config = {
      method: "post",
      url: API_BASE_URL + `/rejectionTrendGrid?start=${start}&offset=${offset}`,
      data: reqBody,
    };
    return dataDisplayRequest(config).then((response) => {
      return response;
    });
  }

  getRejectingJournalCascadeChart(start, offset, reqBody) {
    const config = {
      method: "post",
      url:
        API_BASE_URL +
        `/rejectingJournalCascadeChart?start=${start}&offset=${offset}`,
      data: reqBody,
    };
    return dataDisplayRequest(config).then((response) => {
      return response;
    });
  }

  getRejectingJournalCascadeGrid(start, offset, reqBody) {
    const config = {
      method: "post",
      url:
        API_BASE_URL +
        `/rejectingJournalCascadeGrid?start=${start}&offset=${offset}`,
      data: reqBody,
    };
    return dataDisplayRequest(config).then((response) => {
      return response;
    });
  }
  // getJournalCascadeChart(start, offset, reqBody) {
  //   const config = {
  //     method: "post",
  //     url:
  //       API_BASE_URL + `/journalCascadeChart?start=${start}&offset=${offset}`,
  //     data: reqBody,
  //   };
  //   return dataDisplayRequest(config).then((response) => {
  //     return response;
  //   });
  // }

  getJournalCascadeExpandableGrid(start, offset, reqBody) {
    const config = {
      method: "post",
      url:
        API_BASE_URL +
        `/journalCascadeExpandableGrid?start=${start}&offset=${offset}`,
      data: reqBody,
    };
    return dataDisplayRequest(config).then((response) => {
      return response;
    });
  }

  getAdvancedAnalyticGrid(start, offset, reqBody) {
    const config = {
      method: "post",
      url: API_BASE_URL + `/getReport?start=${start}&offset=${offset}`,
      // data: reqBody,
    };
    return dataDisplayRequest(config).then((response) => {
      return response;
    });
  }

  getTableauJwtToken() {
    const config = {
      method: "post",
      url: API_BASE_URL + `/getToken`,
      // data: reqBody,
    };
    return dataDisplayRequest(config).then((response) => {
      return response;
    });
  }

  forgotPassword(data) {
    const headers = {
      "x-alt-origin": window.location.origin + process.env.PUBLIC_URL,
    };
    return Axios.post(API_BASE_URL + "/api/v1/oauth/forgotPassword", data, {
      headers: headers,
    });
  }

  changePassword(data) {
    return Axios.post(API_BASE_URL + "/api/v1/oauth/changePassword", data);
  }

  registrationConfirm(token) {
    const headers = {
      "x-alt-origin": window.location.origin + process.env.PUBLIC_URL,
    };
    return Axios.get(
      API_BASE_URL + "/api/v1/oauth/registrationConfirm?token=" + token,
      { headers: headers }
    );
  }

  resetPassword(token, data) {
    const headers = {
      "x-alt-origin": window.location.origin + process.env.PUBLIC_URL,
      token: token,
    };
    return Axios.post(API_BASE_URL + "/api/v1/oauth/resetPassword", data, {
      headers: headers,
    });
  }

  getCategoriesChart(start, offset, reqBody) {
    const config = {
      method: "post",
      url: API_BASE_URL + `/categoriesChart_d3?start=${start}&offset=${offset}`,
      data: reqBody,
    };
    return dataDisplayRequest(config).then((response) => {
      return response;
    });
  }

  getCategoriesGrid(start, offset, reqBody) {
    const config = {
      method: "post",
      url: API_BASE_URL + `/categoriesGrid?start=${start}&offset=${offset}`,
      data: reqBody,
    };
    return dataDisplayRequest(config).then((response) => {
      return response;
    });
  }

  getCategoriesExpandableGrid(start, offset, reqBody) {
    const config = {
      method: "post",
      url:
        API_BASE_URL +
        `/categoriesExpandableGrid?start=${start}&offset=${offset}`,
      data: reqBody,
    };
    return dataDisplayRequest(config).then((response) => {
      return response;
    });
  }

  fetchHamburgetMenu() {
    const config = {
      method: "post",
      url: API_BASE_URL + `/hamburgerMenu`,
    };
    return dataDisplayRequest(config).then((response) => {
      return response;
    });
  }

  fetchFilterMenu() {
    const config = {
      method: "post",
      url: API_BASE_URL + `/filterMenu`,
    };
    return dataDisplayRequest(config).then((response) => {
      return response;
    });
  }

  exportAllRecords(gridType, reqBody) {
    const config = {
      method: "post",
      responseType: "blob",
      url: API_BASE_URL + `/export/${gridType}`,
      data: reqBody,
    };
    return dataDisplayRequest(config).then((response) => {
      return response;
    });
  }

  // exportMetaData() {
  //   const config = {
  //     method: "post",
  //     responseType: "blob",
  //     url: API_BASE_URL + `/exportMetaData`,
  //     // data: reqBody,
  //   };
  //   return dataDisplayRequest(config).then((response) => {
  //     return response;
  //   });
  // }

  getEditorReport(reqBody) {
    const config = {
      method: "post",
      url: API_BASE_URL + `/editorReport`,
      data: reqBody,
    };
    return dataDisplayRequest(config).then((response) => {
      return response;
    });
  }

  getAcceptJournalWithPublishers(start, offset, reqBody) {
    const config = {
      method: "post",
      url:
        API_BASE_URL +
        `/acceptJournalTrendGridWithPublishers?start=${start}&offset=${offset}`,
      data: reqBody,
    };
    return dataDisplayRequest(config).then((response) => {
      return response;
    });
  }

  getEditorialReportPieChart(reqBody) {
    const config = {
      method: "post",
      url: API_BASE_URL + `/editorReportPieChart`,
      data: reqBody,
    };
    return dataDisplayRequest(config).then((response) => {
      return response;
    });
  }

  // Chart to image conversation logic

  async highchartsSVGtoImage(svg, height, width) {
    return new Promise((resolve, reject) => {
      var canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;
      var ctx = canvas.getContext("2d");

      var img = document.createElement("img");

      img.onload = function () {
        ctx.drawImage(img, 0, 0);
        resolve(canvas.toDataURL("image/png"));
      };

      img.setAttribute(
        "src",
        "data:image/svg+xml;base64," + btoa(unescape(encodeURIComponent(svg)))
      );
    });
  }
}
