import React, { useEffect } from 'react'
import Filter, { handleGAEvent } from '../../components/Filter/Filter'
import { useState } from 'react'
import AxiosService from '../../utils/AxiosService'
import { useDispatch, useSelector } from 'react-redux'
import { setDateRange, setTableLoader } from '../../Redux/Action/filter.action'
import TableGridLayout from '../../components/TableGridLayout/TableGridLayout'
import DonutGraph from '../../components/graphs/D3/DonutGraph/DonutGraph';

const AccessType = () => {
  const [loading, setLoading] = useState({isApplyFilter: false, isClearFilter: false, isGo:false})
  const {
    journalType, dateRange, searchValue, currentPage,
    acceptingJournal, rejectingJournal, publishers, accessType,
    status, isPageReload, countries
  } = useSelector((state)=> state.allFilters)

  const dispatch = useDispatch()
  const axiosService = new AxiosService()

  const [gridData, setGridData] = useState({})

  const [start, setStart] = useState(0)
  const offset = 10

  const [chartData, setChartData] = useState({})
  const [chartLoader, setChartLoader] = useState(false)
  const [sort, setSort] = useState({
    field: "totalPublication",
    order: "desc"
  })

  const getAllRecords= async ()=>{
    let startDate=""
    let endDate=""

    if(dateRange.rejectionStartDate.length > 10 || dateRange.rejectionEndDate.length > 10) {
      startDate= dateRange.rejectionStartDate.slice(0,10)
      endDate= dateRange.rejectionEndDate.slice(0,10)
    }else {
      startDate= dateRange.rejectionStartDate
      endDate= dateRange.rejectionEndDate
    }

    dispatch(setTableLoader(true))
    const start = (currentPage - 1) * offset;
    setStart(start)

    const reqBody = {
      "vendor": "",
      "start_date": startDate,
      "end_date": endDate,
      "status": status,
      "searchTerm": searchValue,
      "filter": {
        "s_journal": rejectingJournal,
        "p_journal": acceptingJournal,
        "p_publisher": publishers,
        "country": countries
      },
      "sort": {
        "field": sort.field,
        "order": sort.order
      }
      
    }

    if(accessType){
      reqBody["filter"].oa= true
    }

    axiosService.getAccessTypeGridDetails(start, offset, reqBody).then(res=> {
      setLoading({...loading, isApplyFilter:false, isClearFilter: false, isGo:false})
      dispatch(setTableLoader(false))
      setGridData(res?.data)
    }).catch(error=> {
      setGridData({})
      setLoading({...loading, isApplyFilter:false, isClearFilter: false, isGo:false})
      dispatch(setTableLoader(false))
    })

    setChartLoader(true)
    axiosService.getAccessTypeChartDetails(start, offset, reqBody).then(res=> {
      setLoading({...loading, isApplyFilter:false, isClearFilter: false, isGo:false})
      dispatch(setTableLoader(false))
      setChartData(res?.data)
      setChartLoader(false)
    }).catch(error=> {
      setLoading({...loading, isApplyFilter:false, isClearFilter: false, isGo:false})
      dispatch(setTableLoader(false))
      setChartLoader(false)
      setChartData({})
    })
    handleGAEvent(loading, reqBody) 
  }

  useEffect(()=> {
    if((loading.isApplyFilter || loading.isClearFilter || loading.isGo) && dateRange.rejectionStartDate){
      setSort({
        field: "totalPublication",
        order: "desc"
    })
    }
  },[loading.isApplyFilter, loading.isClearFilter, loading.isGo])

  useEffect(()=> {
    if(dateRange.rejectionStartDate){
      getAllRecords()
    }
  }, [currentPage, sort, isPageReload])

  useEffect(()=>{
    window.scrollTo({top: 0, behavior: 'smooth'})
  },[])
  
  return (
    <div>
      <Filter
      loading={loading}
      setLoading={setLoading}
      getAllRecords={getAllRecords}
      />
      <DonutGraph data={chartData} loader={chartLoader} />
      <TableGridLayout data={gridData} 
      start={start} setStart={setStart} 
      offset={offset} 
      gridTitle={"Access Type"}
      getAllRecords={getAllRecords}
      sort={sort}
      setSort={setSort}
      />
    </div>
  )
}

export default AccessType
