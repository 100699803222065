import RoutesList from "./Routes";
import Footer from "./components/Layout/Footer";
import Header from "./components/Layout/Header";
import { BrowserRouter } from "react-router-dom";
import ManuscriptDetailsModal from "./components/Modals/ManuscriptDetailsModal/ManuscriptDetailsModal";
import Login from "./components/Login/Login";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import ScrollToTopButton from "./components/Layout/ScrollToTop/ScrollToTopButton";
import { useEffect, useState } from "react";
import AxiosService from "./utils/AxiosService";
import moment from "moment";
import {
  setAcceptingJournalsList,
  setDateRange,
  setPageReload,
  setPublishersList,
  setRejectingJournalsList,
  setCountriesList
} from "./Redux/Action/filter.action";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

function App() {
  const [filterMenu, setFilterMenu] = useState()
  const currentPage = window.location.pathname.split("/");
  const apiService = new AxiosService();
  const dispatch = useDispatch();
  const resetPassword = window.location.pathname.includes("resetPassword");
  const getMetaInfo = () => {
    dispatch(setPageReload(true));
    apiService
      .getMetaInfo("")
      .then((response) => {
        if (
          response?.data?.rejectionStartDate !== "-Infinity" &&
          response?.data?.rejectionEndDate !== "-Infinity" &&
          response?.data?.lastUpdatedDate !== "-Infinity"
        ) {
          const m1 = moment(response?.data?.rejectionStartDate, "YYYY-MM-DD");
          const m2 = moment(response?.data?.rejectionEndDate, "YYYY-MM-DD");
          dispatch(
            setDateRange({
              rejectionStartDate: m1.format("YYYY-MM-DD"),
              rejectionEndDate: m2.format("YYYY-MM-DD"),
              defualtStartDate: m1.format("YYYY-MM-DD"),
              defualtEndDate: m2.format("YYYY-MM-DD"),
              lastUpdatedDate: response?.data?.lastUpdatedDate,
            })
          );
        } else {
          const m1 = moment(new Date(), "YYYY-MM-DD");
          dispatch(
            setDateRange({
              rejectionStartDate: m1.format("YYYY-MM-DD"),
              rejectionEndDate: m1.format("YYYY-MM-DD"),
              defualtStartDate: m1.format("YYYY-MM-DD"),
              defualtEndDate: m1.format("YYYY-MM-DD"),
              lastUpdatedDate: m1.format("YYYY-MM-DD"),
            })
          );
        }
        dispatch(setPageReload(false));
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Something went wrong!");
        }
        const m1 = moment(new Date(), "YYYY-MM-DD");
        dispatch(
          setDateRange({
            rejectionStartDate: m1.format("YYYY-MM-DD"),
            rejectionEndDate: m1.format("YYYY-MM-DD"),
            defualtStartDate: m1.format("YYYY-MM-DD"),
            defualtEndDate: m1.format("YYYY-MM-DD"),
            lastUpdatedDate: m1.format("YYYY-MM-DD"),
          })
        );
        dispatch(setPageReload(false));
      });
  };

  const fetchRejectedJournalOptions = () => {
    apiService
      .getFilterSuggestion({
        vendor: "",
        field: "s_journal",
        status: "",
        query: "",
        start: "0",
        offset: "1000",
      })
      .then((res) => {
        if (res?.data && typeof res?.data !== "string") {
          dispatch(setRejectingJournalsList(res.data));
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const fetchAccptedJournalOptions = () => {
    apiService
      .getFilterSuggestion({
        vendor: "",
        field: "p_journal",
        query: "",
        status: "resolved",
        start: "0",
        offset: "5000",
      })
      .then((res) => {
        if (res?.data && typeof res?.data !== "string") {
          dispatch(setAcceptingJournalsList(res.data));
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const fetchPublishersOptions = () => {
    apiService
      .getFilterSuggestion({
        vendor: "",
        field: "p_publisher",
        query: "",
        status: "resolved",
        start: "0",
        offset: "1000",
      })
      .then((res) => {
        if (res?.data && typeof res?.data !== "string") {
          dispatch(setPublishersList(res.data));
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const fetchCountriesOptions = () => {
    apiService
      .getFilterSuggestion({
        vendor: "",
        field: "country",
        query: "",
        status: "",
        start: "0",
        offset: "300",
      })
      .then((res) => {
        if (res?.data && typeof res?.data !== "string") {
          dispatch(setCountriesList(res.data));
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  function getFilterMenu() {
    apiService
      .fetchFilterMenu()
      .then((res) => {
        setFilterMenu(res?.data?.result?.configs);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  }

  useEffect(() => {
    getFilterMenu();
  }, []);

  useEffect(() => {
   if(filterMenu?.includes("country")){
    fetchCountriesOptions()
   }
  }, [filterMenu]);

  useEffect(() => {
    if (!resetPassword) {
      getMetaInfo();
      fetchRejectedJournalOptions();
      fetchAccptedJournalOptions();
      fetchPublishersOptions();
      // fetchCountriesOptions()
    }
  }, []);

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <div>
        {currentPage.splice(-1)[0] !== "resetPassword" ? <Header /> : null}
        <main className="bg-light mainContainer ">
          <div className="container-fluid bg-light mc-px-5">
            <RoutesList />
          </div>
          {!resetPassword && <Login />}
          <ManuscriptDetailsModal />
        </main>

        <ScrollToTopButton />
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
