import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  handleFormChange,
  setAcceptingJournals,
  setAccessType,
  setCountries,
  setCurrentPage,
  setPublishers,
  setRejectingJournals,
} from "../../Redux/Action/filter.action";
import DateRange from "../DateRange/DateRange";
import { Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faXmark } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import style from "./Filter.module.scss";
import { useLocation, useNavigate } from 'react-router-dom';
import MultiSelectCheckbox from "../CustomeAutoComplete/MultiSelectCheckbox";
import { ButtonLoader } from "../Loader/Loader";
import openAccessLogo from "../../assets/655px-Open_Access_logo.png";
import TooltipButton from "../TooltipButton/TooltipButton";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import ReactGA from "react-ga4"

export var title = "";
const tooltipMessage = `The filter panel allows single or multiple parameters to refine search results and analytics. Please note that the filters applied will be carried forward to the next insights.`;

let filterBreakPoint = 4
export function toTitleCase(str) {
  return str
    .toLowerCase()
    .split(" ")
    .map(function (word) {
      if (word === "by" || word === "to") {
        return word;
      } else if (word === "vs") {
        return word + ".";
      } else {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }
    })
    .join(" ");
}

export function convertObjectToString(object) {
 const dataString =  Object.keys(object).map(k=> {
  if(!object[k].length){
    return  Object.keys(object[k]).map(e => {
      return Array.isArray(object[k][e]) ? `${e}=${object[k][e].length}` : object[k][e]
    }).join(",")
  }else {
    return `"${object[k]}"`
  }  
 } ).join(",")
 return dataString
}

export function handleGAEvent(loading, reqBody){
  const data = convertObjectToString(reqBody)
  if(loading.isApplyFilter){
    ReactGA.event({
      category: toTitleCase(title),
      action: 'Apply Filter',
      label: data
    });
  }else if(loading.isClearFilter){
    ReactGA.event({
      category: toTitleCase(title),
      action: 'Clear Filter',
      label: data
    });
  }
}

const Filter = ({
  loading,
  setLoading,
  isVisible = false,
  classes = "",
  current,
  setCurrent,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const activeTab = location.pathname;
  title = activeTab.replaceAll("-", " ").replaceAll("/", "");
  const { rejectingJournalsList, acceptingJournalsList, publishersList, countriesList } = useSelector((state) => state.allFilters);

  const {
    accessType,
    searchValue,
    acceptingJournal,
    rejectingJournal,
    publishers,
    countries
  } = useSelector((state) => state.allFilters);
  const [formValue, setFormValue] = useState({
    rejectingJournal: rejectingJournal,
    acceptingJournal: acceptingJournal,
    publishers: publishers,
    searchValue: searchValue,
    accessType: accessType,
    countries: countries,
  });

  const dispatch = useDispatch();

  const resetFilter = (e) => {
    e.preventDefault();
    setFormValue({
      rejectingJournal: [],
      acceptingJournal: [],
      publishers: [],
      countries:[],
      searchValue: "",
      accessType: false,
    });

    setLoading({ ...loading, isClearFilter: true });
    dispatch(setRejectingJournals([]));
    dispatch(setAcceptingJournals([]));
    dispatch(setPublishers([]));
    dispatch(setCountries([]))
    if (current) {
      setCurrent(1);
    } else {
      dispatch(setCurrentPage(1));
    }
    dispatch(handleFormChange(""));
    dispatch(setAccessType(false));
  };

  const handleClearSearch = () => {
    setFormValue({
      ...formValue,
      searchValue: "",
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValue({
      ...formValue,
      [name]: value,
    });
  };

  const handleApplyFilter = async (e) => {
    e.preventDefault();
    dispatch(setPublishers(formValue.publishers));
    dispatch(setRejectingJournals(formValue.rejectingJournal));
    dispatch(setAcceptingJournals(formValue.acceptingJournal));
    dispatch(setCountries(formValue.countries));
    dispatch(handleFormChange(formValue.searchValue));
    setLoading({ ...loading, isApplyFilter: true });
    if (current) {
      setCurrent(1);
    } else {
      dispatch(setCurrentPage(1));
    }
  };

  const onSelectAllChange = (values, name) => {

    setFormValue({
      ...formValue,
      [name]:values
    });

}

  const onFormChange = (values, name) => {
    console.log("Filter name",name);
    
    if (
      formValue[name].includes(values)
    ) {
      setFormValue({
        ...formValue,
        [name]:
          name === "publishers"
            ? formValue.publishers.filter((v) => v !== values)
            : name === "acceptingJournal"
            ? formValue.acceptingJournal.filter((v) => v !== values)
            : name === "countries"
            ? formValue.countries.filter((v) => v !== values)
            : formValue.rejectingJournal.filter((v) => v !== values),
      });
    } else {
      setFormValue({
        ...formValue,
        [name]:
          name === "publishers"
            ? [...formValue.publishers, values]
            : name === "acceptingJournal"
            ? [...formValue.acceptingJournal, values]
            : name === "countries"
            ? [...formValue.countries, values]
            : [...formValue.rejectingJournal, values],
      });
    }

  
  };

  const handleClearFilterOptions = (name) => {
    if (name) {
      setFormValue({
        ...formValue,
        [name]:
          name === "publishers" ? [] : name === "acceptingJournal" ? [] : [],
      });
    }
  };

  function backToLink ()  {
    navigate("/cumulative-analytics")
  }

  if(countriesList.length > 0){
    filterBreakPoint = 6
  }
  return (
    <div className="pt-3">
      {!isVisible && (
        <Row className="d-flex align-items-baseline">
          <Col xl={6}>
            <h5 className="">
              {title !== "cumulative analytics" && (
                <>
                  <span className="linkText cursor-pointer noUnderline" onClick={backToLink}  >Cumulative Analytics </span>
                  {">"}
                </>
              )}

              {toTitleCase(title)}
            </h5>
          </Col>
          <Col xl={6} className="">
            <DateRange loading={loading} setLoading={setLoading} />
          </Col>
        </Row>
      )}
      <div className=" mt-2">
        <Card className={`${classes ? classes : ""}`}>
          <Card.Body className={`pb-4 ${classes ? classes : ""}`}>
            <div className={classNames("d-flex justify-content-between align-items-center", style.filterHeader)}>
              <h5>Filters
              <TooltipButton tooltipMessage={tooltipMessage} placement='right' type={"string"} classes={"TooltipDiv"}><FontAwesomeIcon icon={faInfoCircle} size='sm' className='fs-14 ps-2 text-secondary' /></TooltipButton>
              </h5>
            </div>
            <form className="mt-1">
              <Row className="mb-3">
                <Col md={filterBreakPoint} className="">
                  <div>
                    <label className="mb-1 mt-2 fs-14">Rejecting Journals</label>
                    <MultiSelectCheckbox
                      options={rejectingJournalsList}
                      title={"All Journals"}
                      name={"rejectingJournal"}
                      placeholder={"Search for Rejecting Journals"}
                      selectedValue={formValue.rejectingJournal}
                      onFormChange={onFormChange}
                      onSelectAllChange={onSelectAllChange}
                      onClear={handleClearFilterOptions}
                    />
                  </div>
                </Col>
                <Col md={filterBreakPoint}>
                  <div>
                    <label className="mb-1 mt-2 fs-14">Accepting Journals</label>
                    <MultiSelectCheckbox
                      options={acceptingJournalsList}
                      title={"All Journals"}
                      name={"acceptingJournal"}
                      placeholder={"Search for Accepting Journals"}
                      selectedValue={formValue.acceptingJournal}
                      onFormChange={onFormChange}
                      onSelectAllChange={onSelectAllChange}
                      onClear={handleClearFilterOptions}
                    />
                  </div>
                </Col>
                <Col md={filterBreakPoint}>
                  <div>
                    <label className="mb-1 mt-2 fs-14">Publishers</label>
                    <MultiSelectCheckbox
                      options={publishersList}
                      title={"All Publishers"}
                      name={"publishers"}
                      placeholder={"Search for Publishers"}
                      selectedValue={formValue.publishers}
                      onFormChange={onFormChange}
                      onSelectAllChange={onSelectAllChange}
                      onClear={handleClearFilterOptions}
                    />
                  </div>
                </Col>
                {countriesList.length > 0 && <Col md={filterBreakPoint}>
                  <div>
                    <label className="mb-1 mt-2 fs-14">Country</label>
                    <MultiSelectCheckbox
                      options={countriesList}
                      title={"All Countries"}
                      name={"countries"}
                      placeholder={"Search for Countries"}
                      selectedValue={formValue.countries}
                      onFormChange={onFormChange}
                      onSelectAllChange={onSelectAllChange}
                      onClear={handleClearFilterOptions}
                    />
                  </div>
                </Col>}
              </Row>
              <Row>
                <Col md={6}>
                  <div>
                    <label className="mb-1 fs-14">Search</label>
                    <InputGroup className={classNames(style.searchStyle)}>
                      <InputGroup.Text className="bg-white pe-0">
                        <FontAwesomeIcon
                          icon={faMagnifyingGlass}
                          size="sm"
                          className="text-muted"
                        />
                      </InputGroup.Text>
                      <Form.Control
                        placeholder="Search by Title / DOI / Journal / Manuscript ID / Decision type"
                        value={formValue.searchValue}
                        onChange={(e) => handleChange(e)}
                        name="searchValue"
                        className={classNames(
                          "border-start-0 border-end-0",
                          style.customInputStyle
                        )}
                      />
                      <InputGroup.Text
                        className="bg-white border-start-0 text-danger cursor-pointer"
                        onClick={handleClearSearch}
                      >
                        {formValue.searchValue?.trim().length > 0 && (
                          <span className="fs-12">
                            <FontAwesomeIcon icon={faXmark} /> Clear
                          </span>
                        )}
                      </InputGroup.Text>
                    </InputGroup>
                  </div>
                </Col>
                <Col lg={2} md={6}>
                  <div>
                    <label className="mb-1  mt-2 fs-14">Access Type</label>
                    <div className="form-check mt-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={formValue.accessType}
                        checked={formValue.accessType}
                        onChange={(e) => {
                          setFormValue({
                            ...formValue,
                            accessType: e.target.checked,
                          });
                          dispatch(setAccessType(e.target.checked));
                        }}
                        id="accessType"
                      />

                      <label className="form-check-label" htmlFor="accessType">
                        <span>
                          <img
                            width="15px"
                            height="20px"
                            className="me-1"
                            src={openAccessLogo}
                            alt="Open access logo"
                          />
                          Published OA
                        </span>
                      </label>
                    </div>
                  </div>
                </Col>
                <Col lg={2} md={6} className=" d-flex align-items-end mt-3">
                  {loading.isApplyFilter ? (
                    <ButtonLoader classes={"btn btn-info btnDefault w-100"} />
                  ) : (
                    <button
                      className="btn btn-info btnDefault w-100"
                      onClick={(e) => handleApplyFilter(e)}
                      disabled={loading.isApplyFilter || loading.isClearFilter}
                    >
                      Apply Filter
                    </button>
                  )}
                </Col>
                <Col lg={2} md={6} className=" d-flex align-items-end mt-3">
                  {loading.isClearFilter ? (
                    <ButtonLoader classes={"btn text-secondary border-secondary w-100"} />
                  ) : (
                    <button
                      className="btn text-secondary border-secondary w-100"
                      onClick={(e) => resetFilter(e)}
                      disabled={loading.isApplyFilter || loading.isClearFilter}
                    >
                      Clear Filter
                    </button>
                  )}
                </Col>
              </Row>
            </form>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default Filter;
