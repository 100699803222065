import React, { useEffect, useState } from 'react'
import Filter, { handleGAEvent } from '../../components/Filter/Filter'
import { useDispatch, useSelector } from 'react-redux'
import AxiosService from '../../utils/AxiosService'
import { setDateRange, setTableLoader } from '../../Redux/Action/filter.action'
import { setJournalGridData } from '../../Redux/Action/journal.action'
import TableGridLayout from '../../components/TableGridLayout/TableGridLayout'
import ToggleTreemap from '../../components/graphs/D3/Treemap/ToggleTreemap'

import "./AcceptingVsSubjectCategory.scss"

const AcceptingVsSubjectCatagory = () => {
  const [loading, setLoading] = useState({isApplyFilter: false, isClearFilter: false, isGo:false, isGraphLoader: false})
  const {
    journalType, dateRange, searchValue, currentPage,
    acceptingJournal, rejectingJournal, publishers, accessType, isPageReload, countries
  } = useSelector((state)=> state.allFilters)

  const {journalGrid} = useSelector((state)=> state.journal)
  const dispatch = useDispatch()
  const axiosService = new AxiosService()

  const [start, setStart] = useState(0)
  const offset = 10
  const[reqBody, setReqBody] = useState({})

  const [chartData, setChartData] = useState({})
  const [sort, setSort] = useState({
    field: "totalPublication",
    order: "desc"
  })

  const [chartLoader, setChartLoader] = useState(false)

  const getAllRecords= async()=>{
    let startDate=""
    let endDate=""

    if(dateRange.rejectionStartDate.length > 10 || dateRange.rejectionEndDate.length > 10) {
      startDate= dateRange.rejectionStartDate.slice(0,10)
      endDate= dateRange.rejectionEndDate.slice(0,10)
    }else {
      startDate= dateRange.rejectionStartDate
      endDate= dateRange.rejectionEndDate
    }
    dispatch(setTableLoader(true))
    const start = (currentPage - 1) * offset;
    setStart(start)

    const reqBody = {
      "vendor": "",
      "start_date": startDate,
      "end_date": endDate,
      "status": "resolved",
      "searchTerm": searchValue,
      "filter": {
        "s_journal": rejectingJournal,
        "p_journal": acceptingJournal,
        "p_publisher": publishers,
        "country": countries
      },
      "sort": {
        "field": sort.field,
        "order": sort.order
      }
    }

    if(accessType){
      reqBody["filter"].oa= true
    }
    setChartLoader(true)

    axiosService.getCategoriesChart(start, offset, reqBody).then(res=> {
      setChartData(res?.data)
      setChartLoader(false)
    }).catch(error=> {
      console.log(error)
      setChartLoader(false)
    })
    
    axiosService.getCategoriesGrid(start, offset, reqBody).then(response => {
      if(response.data) {
          dispatch(setTableLoader(false))
          dispatch(setJournalGridData(response.data))
          setLoading({...loading, isApplyFilter:false, isClearFilter: false, isGo:false})
      }
    }).catch(error => {
      dispatch(setTableLoader(false))
    })  
    setReqBody(reqBody)
    handleGAEvent(loading, reqBody)
  }

  useEffect(()=> {
    if(dateRange.rejectionStartDate) {
      getAllRecords()
    }
  }, [currentPage, sort, isPageReload])

  useEffect(()=> {
    if((loading.isApplyFilter || loading.isClearFilter || loading.isGo) && dateRange.rejectionStartDate){
      getAllRecords()
    }
  },[loading])
  
  useEffect(()=>{
    window.scrollTo({top: 0, behavior: 'smooth'})
  },[])

  return (
    <div>
      <Filter
      loading={loading}
      setLoading={setLoading}
      getAllRecords={getAllRecords}
      />
      <ToggleTreemap data={chartData}
      loader={chartLoader}
      loading={loading}
      setLoader={setChartLoader}
      title="Insights by Accepting Journals vs. Subject Categories"
      graphType="categoriesGraph"
      />

      <TableGridLayout data={journalGrid} 
      start={start} setStart={setStart} 
      offset={offset} 
      gridTitle={"Journals by Publication Count"}
      getAllRecords={getAllRecords}
      sort={sort}
      setSort={setSort}
      graphType={"categoriesGraph"}
      reqBody={reqBody}
      gridType="categoriesGrid"
      />
    </div>
  )
}

export default AcceptingVsSubjectCatagory
